export const Calendar = () => import('../../assets/Icons/Calendar.vue' /* webpackChunkName: "components/calendar" */).then(c => wrapFunctional(c.default || c))
export const Chevron = () => import('../../assets/Icons/Chevron.vue' /* webpackChunkName: "components/chevron" */).then(c => wrapFunctional(c.default || c))
export const CircularCheck = () => import('../../assets/Icons/CircularCheck.vue' /* webpackChunkName: "components/circular-check" */).then(c => wrapFunctional(c.default || c))
export const Cross = () => import('../../assets/Icons/Cross.vue' /* webpackChunkName: "components/cross" */).then(c => wrapFunctional(c.default || c))
export const Facebook = () => import('../../assets/Icons/Facebook.vue' /* webpackChunkName: "components/facebook" */).then(c => wrapFunctional(c.default || c))
export const GiftItem = () => import('../../assets/Icons/GiftItem.vue' /* webpackChunkName: "components/gift-item" */).then(c => wrapFunctional(c.default || c))
export const Instagram = () => import('../../assets/Icons/Instagram.vue' /* webpackChunkName: "components/instagram" */).then(c => wrapFunctional(c.default || c))
export const Linkedin = () => import('../../assets/Icons/Linkedin.vue' /* webpackChunkName: "components/linkedin" */).then(c => wrapFunctional(c.default || c))
export const Lock = () => import('../../assets/Icons/Lock.vue' /* webpackChunkName: "components/lock" */).then(c => wrapFunctional(c.default || c))
export const TestTimer = () => import('../../assets/Icons/TestTimer.vue' /* webpackChunkName: "components/test-timer" */).then(c => wrapFunctional(c.default || c))
export const TickMark = () => import('../../assets/Icons/TickMark.vue' /* webpackChunkName: "components/tick-mark" */).then(c => wrapFunctional(c.default || c))
export const Twitter = () => import('../../assets/Icons/Twitter.vue' /* webpackChunkName: "components/twitter" */).then(c => wrapFunctional(c.default || c))
export const UserIcon = () => import('../../assets/Icons/UserIcon.vue' /* webpackChunkName: "components/user-icon" */).then(c => wrapFunctional(c.default || c))
export const VideoLockedButton = () => import('../../assets/Icons/VideoLockedButton.vue' /* webpackChunkName: "components/video-locked-button" */).then(c => wrapFunctional(c.default || c))
export const VideoPlayButton = () => import('../../assets/Icons/VideoPlayButton.vue' /* webpackChunkName: "components/video-play-button" */).then(c => wrapFunctional(c.default || c))
export const Youtube = () => import('../../assets/Icons/Youtube.vue' /* webpackChunkName: "components/youtube" */).then(c => wrapFunctional(c.default || c))
export const ArticlesArticleSlug = () => import('../../components/Articles/ArticleSlug.vue' /* webpackChunkName: "components/articles-article-slug" */).then(c => wrapFunctional(c.default || c))
export const ArticlesArticleSlugWrapper = () => import('../../components/Articles/ArticleSlugWrapper.vue' /* webpackChunkName: "components/articles-article-slug-wrapper" */).then(c => wrapFunctional(c.default || c))
export const ArticlesBlogListing = () => import('../../components/Articles/BlogListing.vue' /* webpackChunkName: "components/articles-blog-listing" */).then(c => wrapFunctional(c.default || c))
export const AuthContainer = () => import('../../components/Auth/AuthContainer.vue' /* webpackChunkName: "components/auth-container" */).then(c => wrapFunctional(c.default || c))
export const AuthMethodSelection = () => import('../../components/Auth/AuthMethodSelection.vue' /* webpackChunkName: "components/auth-method-selection" */).then(c => wrapFunctional(c.default || c))
export const AuthModal = () => import('../../components/Auth/AuthModal.vue' /* webpackChunkName: "components/auth-modal" */).then(c => wrapFunctional(c.default || c))
export const AuthCountryCodeSearchModal = () => import('../../components/Auth/CountryCodeSearchModal.vue' /* webpackChunkName: "components/auth-country-code-search-modal" */).then(c => wrapFunctional(c.default || c))
export const AuthEmailInput = () => import('../../components/Auth/EmailInput.vue' /* webpackChunkName: "components/auth-email-input" */).then(c => wrapFunctional(c.default || c))
export const AuthExamDateSelectorDrawer = () => import('../../components/Auth/ExamDateSelectorDrawer.vue' /* webpackChunkName: "components/auth-exam-date-selector-drawer" */).then(c => wrapFunctional(c.default || c))
export const AuthExamDetailsFillingModal = () => import('../../components/Auth/ExamDetailsFillingModal.vue' /* webpackChunkName: "components/auth-exam-details-filling-modal" */).then(c => wrapFunctional(c.default || c))
export const AuthMobileInput = () => import('../../components/Auth/MobileInput.vue' /* webpackChunkName: "components/auth-mobile-input" */).then(c => wrapFunctional(c.default || c))
export const AuthOTPInput = () => import('../../components/Auth/OTPInput.vue' /* webpackChunkName: "components/auth-o-t-p-input" */).then(c => wrapFunctional(c.default || c))
export const AuthOtpTimer = () => import('../../components/Auth/OtpTimer.vue' /* webpackChunkName: "components/auth-otp-timer" */).then(c => wrapFunctional(c.default || c))
export const AuthPhoneAndEmailSignup = () => import('../../components/Auth/PhoneAndEmailSignup.vue' /* webpackChunkName: "components/auth-phone-and-email-signup" */).then(c => wrapFunctional(c.default || c))
export const AuthSignupForm = () => import('../../components/Auth/SignupForm.vue' /* webpackChunkName: "components/auth-signup-form" */).then(c => wrapFunctional(c.default || c))
export const AuthSocialAuthSignup = () => import('../../components/Auth/SocialAuthSignup.vue' /* webpackChunkName: "components/auth-social-auth-signup" */).then(c => wrapFunctional(c.default || c))
export const AuthTabs = () => import('../../components/Auth/Tabs.vue' /* webpackChunkName: "components/auth-tabs" */).then(c => wrapFunctional(c.default || c))
export const AuthVerifyOtp = () => import('../../components/Auth/VerifyOtp.vue' /* webpackChunkName: "components/auth-verify-otp" */).then(c => wrapFunctional(c.default || c))
export const DashboardBriefingModal = () => import('../../components/Dashboard/DashboardBriefingModal.vue' /* webpackChunkName: "components/dashboard-briefing-modal" */).then(c => wrapFunctional(c.default || c))
export const DashboardLayout = () => import('../../components/Dashboard/DashboardLayout.vue' /* webpackChunkName: "components/dashboard-layout" */).then(c => wrapFunctional(c.default || c))
export const DashboardModesTour = () => import('../../components/Dashboard/DashboardModesTour.vue' /* webpackChunkName: "components/dashboard-modes-tour" */).then(c => wrapFunctional(c.default || c))
export const DashboardDropdownButton = () => import('../../components/Dashboard/DropdownButton.vue' /* webpackChunkName: "components/dashboard-dropdown-button" */).then(c => wrapFunctional(c.default || c))
export const DownloadAppInstallBanner = () => import('../../components/DownloadApp/AppInstallBanner.vue' /* webpackChunkName: "components/download-app-install-banner" */).then(c => wrapFunctional(c.default || c))
export const DownloadAppBannerLeftContainer = () => import('../../components/DownloadApp/DownloadAppBannerLeftContainer.vue' /* webpackChunkName: "components/download-app-banner-left-container" */).then(c => wrapFunctional(c.default || c))
export const DownloadAppNavbarNudge = () => import('../../components/DownloadApp/DownloadAppNavbarNudge.vue' /* webpackChunkName: "components/download-app-navbar-nudge" */).then(c => wrapFunctional(c.default || c))
export const DownloadAppNudgeBanner = () => import('../../components/DownloadApp/DownloadAppNudgeBanner.vue' /* webpackChunkName: "components/download-app-nudge-banner" */).then(c => wrapFunctional(c.default || c))
export const DownloadAppPWA = () => import('../../components/DownloadApp/DownloadAppPWA.vue' /* webpackChunkName: "components/download-app-p-w-a" */).then(c => wrapFunctional(c.default || c))
export const DownloadAppQRContainer = () => import('../../components/DownloadApp/DownloadAppQRContainer.vue' /* webpackChunkName: "components/download-app-q-r-container" */).then(c => wrapFunctional(c.default || c))
export const DownloadAppScreensContainer = () => import('../../components/DownloadApp/DownloadAppScreensContainer.vue' /* webpackChunkName: "components/download-app-screens-container" */).then(c => wrapFunctional(c.default || c))
export const DownloadAppWeb = () => import('../../components/DownloadApp/DownloadAppWeb.vue' /* webpackChunkName: "components/download-app-web" */).then(c => wrapFunctional(c.default || c))
export const ErrorPage404 = () => import('../../components/Error/Page404.vue' /* webpackChunkName: "components/error-page404" */).then(c => wrapFunctional(c.default || c))
export const ErrorPage500 = () => import('../../components/Error/Page500.vue' /* webpackChunkName: "components/error-page500" */).then(c => wrapFunctional(c.default || c))
export const Home = () => import('../../components/Home/Home.vue' /* webpackChunkName: "components/home" */).then(c => wrapFunctional(c.default || c))
export const HomeHowItWorksPWA = () => import('../../components/Home/HowItWorksPWA.vue' /* webpackChunkName: "components/home-how-it-works-p-w-a" */).then(c => wrapFunctional(c.default || c))
export const HomeHowItWorksWeb = () => import('../../components/Home/HowItWorksWeb.vue' /* webpackChunkName: "components/home-how-it-works-web" */).then(c => wrapFunctional(c.default || c))
export const HomeInstructionsModal = () => import('../../components/Home/InstructionsModal.vue' /* webpackChunkName: "components/home-instructions-modal" */).then(c => wrapFunctional(c.default || c))
export const HomeLiveTestNotActiveModal = () => import('../../components/Home/LiveTestNotActiveModal.vue' /* webpackChunkName: "components/home-live-test-not-active-modal" */).then(c => wrapFunctional(c.default || c))
export const HooksCollegeFinderBanner = () => import('../../components/Hooks/CollegeFinderBanner.vue' /* webpackChunkName: "components/hooks-college-finder-banner" */).then(c => wrapFunctional(c.default || c))
export const IconsRupee = () => import('../../components/Icons/Rupee.vue' /* webpackChunkName: "components/icons-rupee" */).then(c => wrapFunctional(c.default || c))
export const LeadMagnetAndFreebiesDownloadCheatsheetCard = () => import('../../components/LeadMagnetAndFreebies/DownloadCheatsheetCard.vue' /* webpackChunkName: "components/lead-magnet-and-freebies-download-cheatsheet-card" */).then(c => wrapFunctional(c.default || c))
export const LeadMagnetAndFreebiesFreebieTestCard = () => import('../../components/LeadMagnetAndFreebies/FreebieTestCard.vue' /* webpackChunkName: "components/lead-magnet-and-freebies-freebie-test-card" */).then(c => wrapFunctional(c.default || c))
export const NavbarQdTestHook = () => import('../../components/Navbar/QdTestHook.vue' /* webpackChunkName: "components/navbar-qd-test-hook" */).then(c => wrapFunctional(c.default || c))
export const NavbarYocketProductsRedirectionsDropdown = () => import('../../components/Navbar/YocketProductsRedirectionsDropdown.vue' /* webpackChunkName: "components/navbar-yocket-products-redirections-dropdown" */).then(c => wrapFunctional(c.default || c))
export const OnDemandFeatureCard = () => import('../../components/OnDemandLP/OnDemandFeatureCard.vue' /* webpackChunkName: "components/on-demand-feature-card" */).then(c => wrapFunctional(c.default || c))
export const OnDemandGifContainer = () => import('../../components/OnDemandLP/OnDemandGifContainer.vue' /* webpackChunkName: "components/on-demand-gif-container" */).then(c => wrapFunctional(c.default || c))
export const OnDemandHeroCard = () => import('../../components/OnDemandLP/OnDemandHeroCard.vue' /* webpackChunkName: "components/on-demand-hero-card" */).then(c => wrapFunctional(c.default || c))
export const OnDemandLPContainer = () => import('../../components/OnDemandLP/OnDemandLPContainer.vue' /* webpackChunkName: "components/on-demand-l-p-container" */).then(c => wrapFunctional(c.default || c))
export const OnDemandRegisterBanner = () => import('../../components/OnDemandLP/OnDemandRegisterBanner.vue' /* webpackChunkName: "components/on-demand-register-banner" */).then(c => wrapFunctional(c.default || c))
export const OnDemandLPSectionHeading = () => import('../../components/OnDemandLP/SectionHeading.vue' /* webpackChunkName: "components/on-demand-l-p-section-heading" */).then(c => wrapFunctional(c.default || c))
export const OnDemandLPUserImages = () => import('../../components/OnDemandLP/UserImages.vue' /* webpackChunkName: "components/on-demand-l-p-user-images" */).then(c => wrapFunctional(c.default || c))
export const OnDemandFeatureCardV2 = () => import('../../components/OnDemandLPV2/OnDemandFeatureCardV2.vue' /* webpackChunkName: "components/on-demand-feature-card-v2" */).then(c => wrapFunctional(c.default || c))
export const OnDemandGifContainerV2 = () => import('../../components/OnDemandLPV2/OnDemandGifContainerV2.vue' /* webpackChunkName: "components/on-demand-gif-container-v2" */).then(c => wrapFunctional(c.default || c))
export const OnDemandHeroCardV2 = () => import('../../components/OnDemandLPV2/OnDemandHeroCardV2.vue' /* webpackChunkName: "components/on-demand-hero-card-v2" */).then(c => wrapFunctional(c.default || c))
export const OnDemandLPContainerV2 = () => import('../../components/OnDemandLPV2/OnDemandLPContainerV2.vue' /* webpackChunkName: "components/on-demand-l-p-container-v2" */).then(c => wrapFunctional(c.default || c))
export const OnDemandRegisterBannerV2 = () => import('../../components/OnDemandLPV2/OnDemandRegisterBannerV2.vue' /* webpackChunkName: "components/on-demand-register-banner-v2" */).then(c => wrapFunctional(c.default || c))
export const OnDemandLPV2SectionHeadingV2 = () => import('../../components/OnDemandLPV2/SectionHeadingV2.vue' /* webpackChunkName: "components/on-demand-l-p-v2-section-heading-v2" */).then(c => wrapFunctional(c.default || c))
export const OnDemandLPV2UserImagesV2 = () => import('../../components/OnDemandLPV2/UserImagesV2.vue' /* webpackChunkName: "components/on-demand-l-p-v2-user-images-v2" */).then(c => wrapFunctional(c.default || c))
export const OverlayPromptsExpiryOverlayNudge = () => import('../../components/OverlayPrompts/ExpiryOverlayNudge.vue' /* webpackChunkName: "components/overlay-prompts-expiry-overlay-nudge" */).then(c => wrapFunctional(c.default || c))
export const OverlayPromptsTrialExpiryOverlay = () => import('../../components/OverlayPrompts/TrialExpiryOverlay.vue' /* webpackChunkName: "components/overlay-prompts-trial-expiry-overlay" */).then(c => wrapFunctional(c.default || c))
export const OverlayPromptsTrialPeriodHomeScreenPwa = () => import('../../components/OverlayPrompts/TrialPeriodHomeScreenPwa.vue' /* webpackChunkName: "components/overlay-prompts-trial-period-home-screen-pwa" */).then(c => wrapFunctional(c.default || c))
export const PremiumGetPlusStickyCta = () => import('../../components/Premium/GetPlusStickyCta.vue' /* webpackChunkName: "components/premium-get-plus-sticky-cta" */).then(c => wrapFunctional(c.default || c))
export const PremiumPlanExpiryOverlay = () => import('../../components/Premium/PlanExpiryOverlay.vue' /* webpackChunkName: "components/premium-plan-expiry-overlay" */).then(c => wrapFunctional(c.default || c))
export const ProfileAboutProfile = () => import('../../components/Profile/AboutProfile.vue' /* webpackChunkName: "components/profile-about-profile" */).then(c => wrapFunctional(c.default || c))
export const ProfileBillingDetails = () => import('../../components/Profile/BillingDetails.vue' /* webpackChunkName: "components/profile-billing-details" */).then(c => wrapFunctional(c.default || c))
export const ProfileLogoutPopupPwa = () => import('../../components/Profile/LogoutPopupPwa.vue' /* webpackChunkName: "components/profile-logout-popup-pwa" */).then(c => wrapFunctional(c.default || c))
export const ProfilePic = () => import('../../components/Profile/ProfilePic.vue' /* webpackChunkName: "components/profile-pic" */).then(c => wrapFunctional(c.default || c))
export const ProfilePwa = () => import('../../components/Profile/ProfilePwa.vue' /* webpackChunkName: "components/profile-pwa" */).then(c => wrapFunctional(c.default || c))
export const ProfileWeb = () => import('../../components/Profile/ProfileWeb.vue' /* webpackChunkName: "components/profile-web" */).then(c => wrapFunctional(c.default || c))
export const QuestionsPwa = () => import('../../components/Questions/QuestionsPwa.vue' /* webpackChunkName: "components/questions-pwa" */).then(c => wrapFunctional(c.default || c))
export const QuestionsWeb = () => import('../../components/Questions/QuestionsWeb.vue' /* webpackChunkName: "components/questions-web" */).then(c => wrapFunctional(c.default || c))
export const Report = () => import('../../components/Report/Report.vue' /* webpackChunkName: "components/report" */).then(c => wrapFunctional(c.default || c))
export const ReusableComponentsAccordion = () => import('../../components/ReusableComponents/Accordion.vue' /* webpackChunkName: "components/reusable-components-accordion" */).then(c => wrapFunctional(c.default || c))
export const ReusableComponentsActivityNudge = () => import('../../components/ReusableComponents/ActivityNudge.vue' /* webpackChunkName: "components/reusable-components-activity-nudge" */).then(c => wrapFunctional(c.default || c))
export const ReusableComponentsAppOfferBanner = () => import('../../components/ReusableComponents/AppOfferBanner.vue' /* webpackChunkName: "components/reusable-components-app-offer-banner" */).then(c => wrapFunctional(c.default || c))
export const ReusableComponentsAppWideFeatureInfoBanner = () => import('../../components/ReusableComponents/AppWideFeatureInfoBanner.vue' /* webpackChunkName: "components/reusable-components-app-wide-feature-info-banner" */).then(c => wrapFunctional(c.default || c))
export const ReusableComponentsBanner = () => import('../../components/ReusableComponents/Banner.vue' /* webpackChunkName: "components/reusable-components-banner" */).then(c => wrapFunctional(c.default || c))
export const ReusableComponentsBannerBar = () => import('../../components/ReusableComponents/BannerBar.vue' /* webpackChunkName: "components/reusable-components-banner-bar" */).then(c => wrapFunctional(c.default || c))
export const ReusableComponentsCardTag = () => import('../../components/ReusableComponents/CardTag.vue' /* webpackChunkName: "components/reusable-components-card-tag" */).then(c => wrapFunctional(c.default || c))
export const ReusableComponentsChipSelection = () => import('../../components/ReusableComponents/ChipSelection.vue' /* webpackChunkName: "components/reusable-components-chip-selection" */).then(c => wrapFunctional(c.default || c))
export const ReusableComponentsComingSoonTag = () => import('../../components/ReusableComponents/ComingSoonTag.vue' /* webpackChunkName: "components/reusable-components-coming-soon-tag" */).then(c => wrapFunctional(c.default || c))
export const ReusableComponentsCommonFAQSection = () => import('../../components/ReusableComponents/CommonFAQSection.vue' /* webpackChunkName: "components/reusable-components-common-f-a-q-section" */).then(c => wrapFunctional(c.default || c))
export const ReusableComponentsCountdownLoader = () => import('../../components/ReusableComponents/CountdownLoader.vue' /* webpackChunkName: "components/reusable-components-countdown-loader" */).then(c => wrapFunctional(c.default || c))
export const ReusableComponentsCustomSelect = () => import('../../components/ReusableComponents/CustomSelect.vue' /* webpackChunkName: "components/reusable-components-custom-select" */).then(c => wrapFunctional(c.default || c))
export const ReusableComponentsDashboardLoader = () => import('../../components/ReusableComponents/DashboardLoader.vue' /* webpackChunkName: "components/reusable-components-dashboard-loader" */).then(c => wrapFunctional(c.default || c))
export const ReusableComponentsDatePicker = () => import('../../components/ReusableComponents/DatePicker.vue' /* webpackChunkName: "components/reusable-components-date-picker" */).then(c => wrapFunctional(c.default || c))
export const ReusableComponentsDifficultyTag = () => import('../../components/ReusableComponents/DifficultyTag.vue' /* webpackChunkName: "components/reusable-components-difficulty-tag" */).then(c => wrapFunctional(c.default || c))
export const ReusableComponentsDropdown = () => import('../../components/ReusableComponents/Dropdown.vue' /* webpackChunkName: "components/reusable-components-dropdown" */).then(c => wrapFunctional(c.default || c))
export const ReusableComponentsGoogleReCaptcha = () => import('../../components/ReusableComponents/GoogleReCaptcha.vue' /* webpackChunkName: "components/reusable-components-google-re-captcha" */).then(c => wrapFunctional(c.default || c))
export const ReusableComponentsGradientBorderContainer = () => import('../../components/ReusableComponents/GradientBorderContainer.vue' /* webpackChunkName: "components/reusable-components-gradient-border-container" */).then(c => wrapFunctional(c.default || c))
export const ReusableComponentsGradientCheckBg = () => import('../../components/ReusableComponents/GradientCheckBg.vue' /* webpackChunkName: "components/reusable-components-gradient-check-bg" */).then(c => wrapFunctional(c.default || c))
export const ReusableComponentsHelperText = () => import('../../components/ReusableComponents/HelperText.vue' /* webpackChunkName: "components/reusable-components-helper-text" */).then(c => wrapFunctional(c.default || c))
export const ReusableComponentsHorizontalTabsNav = () => import('../../components/ReusableComponents/HorizontalTabsNav.vue' /* webpackChunkName: "components/reusable-components-horizontal-tabs-nav" */).then(c => wrapFunctional(c.default || c))
export const ReusableComponentsIcon = () => import('../../components/ReusableComponents/Icon.vue' /* webpackChunkName: "components/reusable-components-icon" */).then(c => wrapFunctional(c.default || c))
export const ReusableComponentsLoader = () => import('../../components/ReusableComponents/Loader.vue' /* webpackChunkName: "components/reusable-components-loader" */).then(c => wrapFunctional(c.default || c))
export const ReusableComponentsMathJax = () => import('../../components/ReusableComponents/MathJax.vue' /* webpackChunkName: "components/reusable-components-math-jax" */).then(c => wrapFunctional(c.default || c))
export const ReusableComponentsModalContainer = () => import('../../components/ReusableComponents/ModalContainer.vue' /* webpackChunkName: "components/reusable-components-modal-container" */).then(c => wrapFunctional(c.default || c))
export const ReusableComponentsMultiselect = () => import('../../components/ReusableComponents/Multiselect.vue' /* webpackChunkName: "components/reusable-components-multiselect" */).then(c => wrapFunctional(c.default || c))
export const ReusableComponentsNewFeatureTag = () => import('../../components/ReusableComponents/NewFeatureTag.vue' /* webpackChunkName: "components/reusable-components-new-feature-tag" */).then(c => wrapFunctional(c.default || c))
export const ReusableComponentsNewGrePatternTestTag = () => import('../../components/ReusableComponents/NewGrePatternTestTag.vue' /* webpackChunkName: "components/reusable-components-new-gre-pattern-test-tag" */).then(c => wrapFunctional(c.default || c))
export const ReusableComponentsNudgeBanner = () => import('../../components/ReusableComponents/NudgeBanner.vue' /* webpackChunkName: "components/reusable-components-nudge-banner" */).then(c => wrapFunctional(c.default || c))
export const ReusableComponentsNudgeTag = () => import('../../components/ReusableComponents/NudgeTag.vue' /* webpackChunkName: "components/reusable-components-nudge-tag" */).then(c => wrapFunctional(c.default || c))
export const ReusableComponentsOnDemandOfferBanner = () => import('../../components/ReusableComponents/OnDemandOfferBanner.vue' /* webpackChunkName: "components/reusable-components-on-demand-offer-banner" */).then(c => wrapFunctional(c.default || c))
export const ReusableComponentsOptionSelectorDrawer = () => import('../../components/ReusableComponents/OptionSelectorDrawer.vue' /* webpackChunkName: "components/reusable-components-option-selector-drawer" */).then(c => wrapFunctional(c.default || c))
export const ReusableComponentsPhoneInput = () => import('../../components/ReusableComponents/PhoneInput.vue' /* webpackChunkName: "components/reusable-components-phone-input" */).then(c => wrapFunctional(c.default || c))
export const ReusableComponentsPrepFooter = () => import('../../components/ReusableComponents/PrepFooter.vue' /* webpackChunkName: "components/reusable-components-prep-footer" */).then(c => wrapFunctional(c.default || c))
export const ReusableComponentsPurpleGradient = () => import('../../components/ReusableComponents/PurpleGradient.vue' /* webpackChunkName: "components/reusable-components-purple-gradient" */).then(c => wrapFunctional(c.default || c))
export const ReusableComponentsSectionHeading = () => import('../../components/ReusableComponents/SectionHeading.vue' /* webpackChunkName: "components/reusable-components-section-heading" */).then(c => wrapFunctional(c.default || c))
export const ReusableComponentsSentenceSelect = () => import('../../components/ReusableComponents/SentenceSelect.vue' /* webpackChunkName: "components/reusable-components-sentence-select" */).then(c => wrapFunctional(c.default || c))
export const ReusableComponentsSideBar = () => import('../../components/ReusableComponents/SideBar.vue' /* webpackChunkName: "components/reusable-components-side-bar" */).then(c => wrapFunctional(c.default || c))
export const ReusableComponentsSingleselect = () => import('../../components/ReusableComponents/Singleselect.vue' /* webpackChunkName: "components/reusable-components-singleselect" */).then(c => wrapFunctional(c.default || c))
export const ReusableComponentsStepWiseProgressBar = () => import('../../components/ReusableComponents/StepWiseProgressBar.vue' /* webpackChunkName: "components/reusable-components-step-wise-progress-bar" */).then(c => wrapFunctional(c.default || c))
export const ReusableComponentsTestCard = () => import('../../components/ReusableComponents/TestCard.vue' /* webpackChunkName: "components/reusable-components-test-card" */).then(c => wrapFunctional(c.default || c))
export const ReusableComponentsTestimonialCard = () => import('../../components/ReusableComponents/TestimonialCard.vue' /* webpackChunkName: "components/reusable-components-testimonial-card" */).then(c => wrapFunctional(c.default || c))
export const ReusableComponentsTextAnswer = () => import('../../components/ReusableComponents/TextAnswer.vue' /* webpackChunkName: "components/reusable-components-text-answer" */).then(c => wrapFunctional(c.default || c))
export const ReusableComponentsToolTip = () => import('../../components/ReusableComponents/ToolTip.vue' /* webpackChunkName: "components/reusable-components-tool-tip" */).then(c => wrapFunctional(c.default || c))
export const ReusableComponentsTopReviewsBadge = () => import('../../components/ReusableComponents/TopReviewsBadge.vue' /* webpackChunkName: "components/reusable-components-top-reviews-badge" */).then(c => wrapFunctional(c.default || c))
export const ReusableComponentsUpgradeCard = () => import('../../components/ReusableComponents/UpgradeCard.vue' /* webpackChunkName: "components/reusable-components-upgrade-card" */).then(c => wrapFunctional(c.default || c))
export const ReusableComponentsVerticalTabsNav = () => import('../../components/ReusableComponents/VerticalTabsNav.vue' /* webpackChunkName: "components/reusable-components-vertical-tabs-nav" */).then(c => wrapFunctional(c.default || c))
export const SkeletonNavbar = () => import('../../components/Skeleton/Navbar.vue' /* webpackChunkName: "components/skeleton-navbar" */).then(c => wrapFunctional(c.default || c))
export const SolutionReportQuestionModal = () => import('../../components/Solution/ReportQuestionModal.vue' /* webpackChunkName: "components/solution-report-question-modal" */).then(c => wrapFunctional(c.default || c))
export const SolutionSingleQuestion = () => import('../../components/Solution/SingleQuestion.vue' /* webpackChunkName: "components/solution-single-question" */).then(c => wrapFunctional(c.default || c))
export const SolutionCard = () => import('../../components/Solution/SolutionCard.vue' /* webpackChunkName: "components/solution-card" */).then(c => wrapFunctional(c.default || c))
export const SolutionSolutions = () => import('../../components/Solution/Solutions.vue' /* webpackChunkName: "components/solution-solutions" */).then(c => wrapFunctional(c.default || c))
export const SolutionSolutionsContainer = () => import('../../components/Solution/SolutionsContainer.vue' /* webpackChunkName: "components/solution-solutions-container" */).then(c => wrapFunctional(c.default || c))
export const SolutionSolutionsHiddenModal = () => import('../../components/Solution/SolutionsHiddenModal.vue' /* webpackChunkName: "components/solution-solutions-hidden-modal" */).then(c => wrapFunctional(c.default || c))
export const ArticlesCommonComponentsArticleContent = () => import('../../components/Articles/CommonComponents/ArticleContent.vue' /* webpackChunkName: "components/articles-common-components-article-content" */).then(c => wrapFunctional(c.default || c))
export const ArticlesCommonComponentsContinueReadingButton = () => import('../../components/Articles/CommonComponents/ContinueReadingButton.vue' /* webpackChunkName: "components/articles-common-components-continue-reading-button" */).then(c => wrapFunctional(c.default || c))
export const ArticlesCommonComponentsFaqAccordion = () => import('../../components/Articles/CommonComponents/FaqAccordion.vue' /* webpackChunkName: "components/articles-common-components-faq-accordion" */).then(c => wrapFunctional(c.default || c))
export const ArticlesCommonComponentsFaqSection = () => import('../../components/Articles/CommonComponents/FaqSection.vue' /* webpackChunkName: "components/articles-common-components-faq-section" */).then(c => wrapFunctional(c.default || c))
export const ArticlesCommonComponentsSuggestedArticles = () => import('../../components/Articles/CommonComponents/SuggestedArticles.vue' /* webpackChunkName: "components/articles-common-components-suggested-articles" */).then(c => wrapFunctional(c.default || c))
export const ArticlesCommonComponentsTopicPill = () => import('../../components/Articles/CommonComponents/TopicPill.vue' /* webpackChunkName: "components/articles-common-components-topic-pill" */).then(c => wrapFunctional(c.default || c))
export const ArticlesCommonComponentsTopicSelector = () => import('../../components/Articles/CommonComponents/TopicSelector.vue' /* webpackChunkName: "components/articles-common-components-topic-selector" */).then(c => wrapFunctional(c.default || c))
export const ArticlesDeskComponentsArticleDesktop = () => import('../../components/Articles/DeskComponents/ArticleDesktop.vue' /* webpackChunkName: "components/articles-desk-components-article-desktop" */).then(c => wrapFunctional(c.default || c))
export const ArticlesDeskComponentsBlogCardDesktop = () => import('../../components/Articles/DeskComponents/BlogCardDesktop.vue' /* webpackChunkName: "components/articles-desk-components-blog-card-desktop" */).then(c => wrapFunctional(c.default || c))
export const ArticlesDeskComponentsNavBarDropDown = () => import('../../components/Articles/DeskComponents/NavBarDropDown.vue' /* webpackChunkName: "components/articles-desk-components-nav-bar-drop-down" */).then(c => wrapFunctional(c.default || c))
export const ArticlesDeskComponentsTableOfContentListDesktop = () => import('../../components/Articles/DeskComponents/TableOfContentListDesktop.vue' /* webpackChunkName: "components/articles-desk-components-table-of-content-list-desktop" */).then(c => wrapFunctional(c.default || c))
export const ArticlesPWAComponentsArticleHeader = () => import('../../components/Articles/PWAComponents/ArticleHeader.vue' /* webpackChunkName: "components/articles-p-w-a-components-article-header" */).then(c => wrapFunctional(c.default || c))
export const ArticlesPWAComponentsArticleMobile = () => import('../../components/Articles/PWAComponents/ArticleMobile.vue' /* webpackChunkName: "components/articles-p-w-a-components-article-mobile" */).then(c => wrapFunctional(c.default || c))
export const ArticlesPWAComponentsBlogCardMobile = () => import('../../components/Articles/PWAComponents/BlogCardMobile.vue' /* webpackChunkName: "components/articles-p-w-a-components-blog-card-mobile" */).then(c => wrapFunctional(c.default || c))
export const ArticlesPWAComponentsTableOfContentDropdown = () => import('../../components/Articles/PWAComponents/TableOfContentDropdown.vue' /* webpackChunkName: "components/articles-p-w-a-components-table-of-content-dropdown" */).then(c => wrapFunctional(c.default || c))
export const ArticlesPWAComponentsTableOfContentList = () => import('../../components/Articles/PWAComponents/TableOfContentList.vue' /* webpackChunkName: "components/articles-p-w-a-components-table-of-content-list" */).then(c => wrapFunctional(c.default || c))
export const DashboardLearningViewCourseLectureListing = () => import('../../components/Dashboard/LearningView/CourseLectureListing.vue' /* webpackChunkName: "components/dashboard-learning-view-course-lecture-listing" */).then(c => wrapFunctional(c.default || c))
export const DashboardSwitchScreen = () => import('../../components/Dashboard/LearningView/DashboardSwitchScreen.vue' /* webpackChunkName: "components/dashboard-switch-screen" */).then(c => wrapFunctional(c.default || c))
export const DashboardLearningViewFullDiagnosticTestBanner = () => import('../../components/Dashboard/LearningView/FullDiagnosticTestBanner.vue' /* webpackChunkName: "components/dashboard-learning-view-full-diagnostic-test-banner" */).then(c => wrapFunctional(c.default || c))
export const DashboardLearningTour = () => import('../../components/Dashboard/LearningView/LearningTour.vue' /* webpackChunkName: "components/dashboard-learning-tour" */).then(c => wrapFunctional(c.default || c))
export const DashboardLearningViewLectureCardListing = () => import('../../components/Dashboard/LearningView/LectureCardListing.vue' /* webpackChunkName: "components/dashboard-learning-view-lecture-card-listing" */).then(c => wrapFunctional(c.default || c))
export const DashboardLearningViewLockedVideoPopUp = () => import('../../components/Dashboard/LearningView/LockedVideoPopUp.vue' /* webpackChunkName: "components/dashboard-learning-view-locked-video-pop-up" */).then(c => wrapFunctional(c.default || c))
export const DashboardLearningViewPracticeCardListing = () => import('../../components/Dashboard/LearningView/PracticeCardListing.vue' /* webpackChunkName: "components/dashboard-learning-view-practice-card-listing" */).then(c => wrapFunctional(c.default || c))
export const DashboardLearningViewTopicTestCard = () => import('../../components/Dashboard/LearningView/TopicTestCard.vue' /* webpackChunkName: "components/dashboard-learning-view-topic-test-card" */).then(c => wrapFunctional(c.default || c))
export const DashboardLearningViewVideoCard = () => import('../../components/Dashboard/LearningView/VideoCard.vue' /* webpackChunkName: "components/dashboard-learning-view-video-card" */).then(c => wrapFunctional(c.default || c))
export const DashboardLearningViewVideoJsPlayer = () => import('../../components/Dashboard/LearningView/VideoJsPlayer.vue' /* webpackChunkName: "components/dashboard-learning-view-video-js-player" */).then(c => wrapFunctional(c.default || c))
export const DashboardLearningViewVideoPlayer = () => import('../../components/Dashboard/LearningView/VideoPlayer.vue' /* webpackChunkName: "components/dashboard-learning-view-video-player" */).then(c => wrapFunctional(c.default || c))
export const DashboardLearningViewVideoYoutubePlayer = () => import('../../components/Dashboard/LearningView/VideoYoutubePlayer.vue' /* webpackChunkName: "components/dashboard-learning-view-video-youtube-player" */).then(c => wrapFunctional(c.default || c))
export const HomeProgressLine = () => import('../../components/Home/ProgressBar/ProgressLine.vue' /* webpackChunkName: "components/home-progress-line" */).then(c => wrapFunctional(c.default || c))
export const HomeProgressNumber = () => import('../../components/Home/ProgressBar/ProgressNumber.vue' /* webpackChunkName: "components/home-progress-number" */).then(c => wrapFunctional(c.default || c))
export const LiveClassesCommonClassCard = () => import('../../components/LiveClasses/Common/ClassCard.vue' /* webpackChunkName: "components/live-classes-common-class-card" */).then(c => wrapFunctional(c.default || c))
export const LiveClassesCommonEmptySectionState = () => import('../../components/LiveClasses/Common/EmptySectionState.vue' /* webpackChunkName: "components/live-classes-common-empty-section-state" */).then(c => wrapFunctional(c.default || c))
export const LiveClassesCommonLandingPageContainer = () => import('../../components/LiveClasses/Common/LandingPageContainer.vue' /* webpackChunkName: "components/live-classes-common-landing-page-container" */).then(c => wrapFunctional(c.default || c))
export const LiveClassesCommonTimedLoader = () => import('../../components/LiveClasses/Common/TimedLoader.vue' /* webpackChunkName: "components/live-classes-common-timed-loader" */).then(c => wrapFunctional(c.default || c))
export const LiveClassDashboardPwa = () => import('../../components/LiveClasses/PWA/LiveClassDashboardPwa.vue' /* webpackChunkName: "components/live-class-dashboard-pwa" */).then(c => wrapFunctional(c.default || c))
export const LiveClassDashboardWeb = () => import('../../components/LiveClasses/Web/LiveClassDashboardWeb.vue' /* webpackChunkName: "components/live-class-dashboard-web" */).then(c => wrapFunctional(c.default || c))
export const NavbarPwaFeaturesSection = () => import('../../components/Navbar/Pwa/FeaturesSection.vue' /* webpackChunkName: "components/navbar-pwa-features-section" */).then(c => wrapFunctional(c.default || c))
export const NavbarPwaGreetings = () => import('../../components/Navbar/Pwa/Greetings.vue' /* webpackChunkName: "components/navbar-pwa-greetings" */).then(c => wrapFunctional(c.default || c))
export const NavbarPwaMiscellaneousSection = () => import('../../components/Navbar/Pwa/MiscellaneousSection.vue' /* webpackChunkName: "components/navbar-pwa-miscellaneous-section" */).then(c => wrapFunctional(c.default || c))
export const NavbarPwaMobileSideBar = () => import('../../components/Navbar/Pwa/MobileSideBar.vue' /* webpackChunkName: "components/navbar-pwa-mobile-side-bar" */).then(c => wrapFunctional(c.default || c))
export const NavbarPwa = () => import('../../components/Navbar/Pwa/NavbarPwa.vue' /* webpackChunkName: "components/navbar-pwa" */).then(c => wrapFunctional(c.default || c))
export const NavbarPwaRedirectionCards = () => import('../../components/Navbar/Pwa/RedirectionCards.vue' /* webpackChunkName: "components/navbar-pwa-redirection-cards" */).then(c => wrapFunctional(c.default || c))
export const NavbarPwaResourceSection = () => import('../../components/Navbar/Pwa/ResourceSection.vue' /* webpackChunkName: "components/navbar-pwa-resource-section" */).then(c => wrapFunctional(c.default || c))
export const NavbarPwaSidebarItem = () => import('../../components/Navbar/Pwa/SidebarItem.vue' /* webpackChunkName: "components/navbar-pwa-sidebar-item" */).then(c => wrapFunctional(c.default || c))
export const NavbarTabletCourseSelectorTablet = () => import('../../components/Navbar/Tablet/CourseSelectorTablet.vue' /* webpackChunkName: "components/navbar-tablet-course-selector-tablet" */).then(c => wrapFunctional(c.default || c))
export const NavbarTablet = () => import('../../components/Navbar/Tablet/NavbarTablet.vue' /* webpackChunkName: "components/navbar-tablet" */).then(c => wrapFunctional(c.default || c))
export const NavbarTabletResourceSelectorTablet = () => import('../../components/Navbar/Tablet/ResourceSelectorTablet.vue' /* webpackChunkName: "components/navbar-tablet-resource-selector-tablet" */).then(c => wrapFunctional(c.default || c))
export const NavbarTabletSidebar = () => import('../../components/Navbar/Tablet/TabletSidebar.vue' /* webpackChunkName: "components/navbar-tablet-sidebar" */).then(c => wrapFunctional(c.default || c))
export const NavbarTabletSidebarItem = () => import('../../components/Navbar/Tablet/TabletSidebarItem.vue' /* webpackChunkName: "components/navbar-tablet-sidebar-item" */).then(c => wrapFunctional(c.default || c))
export const NavbarTabletSidebarSublistItem = () => import('../../components/Navbar/Tablet/TabletSidebarSublistItem.vue' /* webpackChunkName: "components/navbar-tablet-sidebar-sublist-item" */).then(c => wrapFunctional(c.default || c))
export const NavbarWebDashboardSelector = () => import('../../components/Navbar/Web/DashboardSelector.vue' /* webpackChunkName: "components/navbar-web-dashboard-selector" */).then(c => wrapFunctional(c.default || c))
export const NavbarWebNavItem = () => import('../../components/Navbar/Web/NavItem.vue' /* webpackChunkName: "components/navbar-web-nav-item" */).then(c => wrapFunctional(c.default || c))
export const NavbarWeb = () => import('../../components/Navbar/Web/NavbarWeb.vue' /* webpackChunkName: "components/navbar-web" */).then(c => wrapFunctional(c.default || c))
export const NavbarWebPrepCourseSelector = () => import('../../components/Navbar/Web/PrepCourseSelector.vue' /* webpackChunkName: "components/navbar-web-prep-course-selector" */).then(c => wrapFunctional(c.default || c))
export const NavbarWebPrepToolsSelector = () => import('../../components/Navbar/Web/PrepToolsSelector.vue' /* webpackChunkName: "components/navbar-web-prep-tools-selector" */).then(c => wrapFunctional(c.default || c))
export const NavbarWebRedirectionCard = () => import('../../components/Navbar/Web/RedirectionCard.vue' /* webpackChunkName: "components/navbar-web-redirection-card" */).then(c => wrapFunctional(c.default || c))
export const NavbarWebResourceSelector = () => import('../../components/Navbar/Web/ResourceSelector.vue' /* webpackChunkName: "components/navbar-web-resource-selector" */).then(c => wrapFunctional(c.default || c))
export const NavbarWebYocketRedirections = () => import('../../components/Navbar/Web/YocketRedirections.vue' /* webpackChunkName: "components/navbar-web-yocket-redirections" */).then(c => wrapFunctional(c.default || c))
export const PremiumPaymentStatusModalsFailedModal = () => import('../../components/Premium/PaymentStatusModals/FailedModal.vue' /* webpackChunkName: "components/premium-payment-status-modals-failed-modal" */).then(c => wrapFunctional(c.default || c))
export const PremiumPaymentStatusModalsPendingModal = () => import('../../components/Premium/PaymentStatusModals/PendingModal.vue' /* webpackChunkName: "components/premium-payment-status-modals-pending-modal" */).then(c => wrapFunctional(c.default || c))
export const PremiumPaymentStatusModalsProcessingModal = () => import('../../components/Premium/PaymentStatusModals/ProcessingModal.vue' /* webpackChunkName: "components/premium-payment-status-modals-processing-modal" */).then(c => wrapFunctional(c.default || c))
export const PremiumPaymentStatusModalsSuccessModal = () => import('../../components/Premium/PaymentStatusModals/SuccessModal.vue' /* webpackChunkName: "components/premium-payment-status-modals-success-modal" */).then(c => wrapFunctional(c.default || c))
export const PremiumPaymentsPageCheckoutExitOfferModal = () => import('../../components/Premium/PaymentsPage/CheckoutExitOfferModal.vue' /* webpackChunkName: "components/premium-payments-page-checkout-exit-offer-modal" */).then(c => wrapFunctional(c.default || c))
export const PremiumPaymentsPagePaymentPagePwa = () => import('../../components/Premium/PaymentsPage/PaymentPagePwa.vue' /* webpackChunkName: "components/premium-payments-page-payment-page-pwa" */).then(c => wrapFunctional(c.default || c))
export const PremiumPaymentsPagePaymentPageWeb = () => import('../../components/Premium/PaymentsPage/PaymentPageWeb.vue' /* webpackChunkName: "components/premium-payments-page-payment-page-web" */).then(c => wrapFunctional(c.default || c))
export const PremiumPaymentsPagePriceBreakup = () => import('../../components/Premium/PaymentsPage/PriceBreakup.vue' /* webpackChunkName: "components/premium-payments-page-price-breakup" */).then(c => wrapFunctional(c.default || c))
export const PremiumPaymentsPageUserDetails = () => import('../../components/Premium/PaymentsPage/UserDetails.vue' /* webpackChunkName: "components/premium-payments-page-user-details" */).then(c => wrapFunctional(c.default || c))
export const PriorityPlannerComponentsAccordion = () => import('../../components/PriorityPlanner/Components/Accordion.vue' /* webpackChunkName: "components/priority-planner-components-accordion" */).then(c => wrapFunctional(c.default || c))
export const PriorityPlannerComponentsEditPlannerWarningModal = () => import('../../components/PriorityPlanner/Components/EditPlannerWarningModal.vue' /* webpackChunkName: "components/priority-planner-components-edit-planner-warning-modal" */).then(c => wrapFunctional(c.default || c))
export const PriorityPlannerDatePicker = () => import('../../components/PriorityPlanner/Components/PlannerDatePicker.vue' /* webpackChunkName: "components/priority-planner-date-picker" */).then(c => wrapFunctional(c.default || c))
export const PriorityPlannerLandingContainer = () => import('../../components/PriorityPlanner/LandingPage/PlannerLandingContainer.vue' /* webpackChunkName: "components/priority-planner-landing-container" */).then(c => wrapFunctional(c.default || c))
export const PriorityPlannerLandingPageUserInfoForm = () => import('../../components/PriorityPlanner/LandingPage/UserInfoForm.vue' /* webpackChunkName: "components/priority-planner-landing-page-user-info-form" */).then(c => wrapFunctional(c.default || c))
export const PriorityPlannerPlanLayoutNewFocusPlannerContainer = () => import('../../components/PriorityPlanner/PlanLayoutNew/FocusPlannerContainer.vue' /* webpackChunkName: "components/priority-planner-plan-layout-new-focus-planner-container" */).then(c => wrapFunctional(c.default || c))
export const PriorityPlannerPlanLayoutOldLeftPart = () => import('../../components/PriorityPlanner/PlanLayoutOld/LeftPart.vue' /* webpackChunkName: "components/priority-planner-plan-layout-old-left-part" */).then(c => wrapFunctional(c.default || c))
export const PriorityPlannerPlanLayoutOldRightPart = () => import('../../components/PriorityPlanner/PlanLayoutOld/RightPart.vue' /* webpackChunkName: "components/priority-planner-plan-layout-old-right-part" */).then(c => wrapFunctional(c.default || c))
export const QuestionsFooterPwaFooter = () => import('../../components/Questions/Footer/PwaFooter.vue' /* webpackChunkName: "components/questions-footer-pwa-footer" */).then(c => wrapFunctional(c.default || c))
export const QuestionsFooter = () => import('../../components/Questions/Footer/QuestionsFooter.vue' /* webpackChunkName: "components/questions-footer" */).then(c => wrapFunctional(c.default || c))
export const QuestionsFooterWebFooter = () => import('../../components/Questions/Footer/WebFooter.vue' /* webpackChunkName: "components/questions-footer-web-footer" */).then(c => wrapFunctional(c.default || c))
export const QuestionsModalsExitTestModal = () => import('../../components/Questions/Modals/ExitTestModal.vue' /* webpackChunkName: "components/questions-modals-exit-test-modal" */).then(c => wrapFunctional(c.default || c))
export const QuestionsModalsFinishSectionModal = () => import('../../components/Questions/Modals/FinishSectionModal.vue' /* webpackChunkName: "components/questions-modals-finish-section-modal" */).then(c => wrapFunctional(c.default || c))
export const QuestionsModalsFinishTestModal = () => import('../../components/Questions/Modals/FinishTestModal.vue' /* webpackChunkName: "components/questions-modals-finish-test-modal" */).then(c => wrapFunctional(c.default || c))
export const QuestionsModalsPauseAndSaveModal = () => import('../../components/Questions/Modals/PauseAndSaveModal.vue' /* webpackChunkName: "components/questions-modals-pause-and-save-modal" */).then(c => wrapFunctional(c.default || c))
export const QuestionsModalsTimesUpModal = () => import('../../components/Questions/Modals/TimesUpModal.vue' /* webpackChunkName: "components/questions-modals-times-up-modal" */).then(c => wrapFunctional(c.default || c))
export const QuestionsNavPwa = () => import('../../components/Questions/Navbar/QuestionsNavPwa.vue' /* webpackChunkName: "components/questions-nav-pwa" */).then(c => wrapFunctional(c.default || c))
export const QuestionsNavWeb = () => import('../../components/Questions/Navbar/QuestionsNavWeb.vue' /* webpackChunkName: "components/questions-nav-web" */).then(c => wrapFunctional(c.default || c))
export const QuestionsNavbarTimerNav = () => import('../../components/Questions/Navbar/TimerNav.vue' /* webpackChunkName: "components/questions-navbar-timer-nav" */).then(c => wrapFunctional(c.default || c))
export const ReportCommonComponentsBlurredQdReportOverlay = () => import('../../components/Report/CommonComponents/BlurredQdReportOverlay.vue' /* webpackChunkName: "components/report-common-components-blurred-qd-report-overlay" */).then(c => wrapFunctional(c.default || c))
export const ReportCommonComponentsProgressBar = () => import('../../components/Report/CommonComponents/ProgressBar.vue' /* webpackChunkName: "components/report-common-components-progress-bar" */).then(c => wrapFunctional(c.default || c))
export const ReportLoader = () => import('../../components/Report/CommonComponents/ReportLoader.vue' /* webpackChunkName: "components/report-loader" */).then(c => wrapFunctional(c.default || c))
export const ReportCommonComponentsScoreCard = () => import('../../components/Report/CommonComponents/ScoreCard.vue' /* webpackChunkName: "components/report-common-components-score-card" */).then(c => wrapFunctional(c.default || c))
export const ReportCommonComponentsSubjectStatistics = () => import('../../components/Report/CommonComponents/SubjectStatistics.vue' /* webpackChunkName: "components/report-common-components-subject-statistics" */).then(c => wrapFunctional(c.default || c))
export const ReportCommonComponentsTopicWiseScores = () => import('../../components/Report/CommonComponents/TopicWiseScores.vue' /* webpackChunkName: "components/report-common-components-topic-wise-scores" */).then(c => wrapFunctional(c.default || c))
export const ReportLeftPartDiagnosticsLeftPart = () => import('../../components/Report/LeftPart/DiagnosticsLeftPart.vue' /* webpackChunkName: "components/report-left-part-diagnostics-left-part" */).then(c => wrapFunctional(c.default || c))
export const ReportLeftPartFullOrMiniLeftPart = () => import('../../components/Report/LeftPart/FullOrMiniLeftPart.vue' /* webpackChunkName: "components/report-left-part-full-or-mini-left-part" */).then(c => wrapFunctional(c.default || c))
export const ReportLeftPartTopicLeftPart = () => import('../../components/Report/LeftPart/TopicLeftPart.vue' /* webpackChunkName: "components/report-left-part-topic-left-part" */).then(c => wrapFunctional(c.default || c))
export const ReportRightPartDiagnosticsRightPart = () => import('../../components/Report/RightPart/DiagnosticsRightPart.vue' /* webpackChunkName: "components/report-right-part-diagnostics-right-part" */).then(c => wrapFunctional(c.default || c))
export const ReportRightPartOthersRightPart = () => import('../../components/Report/RightPart/OthersRightPart.vue' /* webpackChunkName: "components/report-right-part-others-right-part" */).then(c => wrapFunctional(c.default || c))
export const VocabBuilderCommonComponentsMcqOption = () => import('../../components/VocabBuilder/CommonComponents/McqOption.vue' /* webpackChunkName: "components/vocab-builder-common-components-mcq-option" */).then(c => wrapFunctional(c.default || c))
export const VocabBuilderCommonComponentsReviseWordsModal = () => import('../../components/VocabBuilder/CommonComponents/ReviseWordsModal.vue' /* webpackChunkName: "components/vocab-builder-common-components-revise-words-modal" */).then(c => wrapFunctional(c.default || c))
export const VocabBuilderCommonComponentsVbInstructionsModal = () => import('../../components/VocabBuilder/CommonComponents/VbInstructionsModal.vue' /* webpackChunkName: "components/vocab-builder-common-components-vb-instructions-modal" */).then(c => wrapFunctional(c.default || c))
export const VocabBuilderCommonComponentsVbUpgradeBanner = () => import('../../components/VocabBuilder/CommonComponents/VbUpgradeBanner.vue' /* webpackChunkName: "components/vocab-builder-common-components-vb-upgrade-banner" */).then(c => wrapFunctional(c.default || c))
export const VocabBuilderCommonComponentsWordExplanation = () => import('../../components/VocabBuilder/CommonComponents/WordExplanation.vue' /* webpackChunkName: "components/vocab-builder-common-components-word-explanation" */).then(c => wrapFunctional(c.default || c))
export const VocabBuilderDashboardVbDashboard = () => import('../../components/VocabBuilder/Dashboard/VbDashboard.vue' /* webpackChunkName: "components/vocab-builder-dashboard-vb-dashboard" */).then(c => wrapFunctional(c.default || c))
export const VocabBuilderReportTable = () => import('../../components/VocabBuilder/Report/ReportTable.vue' /* webpackChunkName: "components/vocab-builder-report-table" */).then(c => wrapFunctional(c.default || c))
export const VocabBuilderReportVbReportContainer = () => import('../../components/VocabBuilder/Report/VbReportContainer.vue' /* webpackChunkName: "components/vocab-builder-report-vb-report-container" */).then(c => wrapFunctional(c.default || c))
export const VocabBuilderWalkthroughVbTour = () => import('../../components/VocabBuilder/Walkthrough/VbTour.vue' /* webpackChunkName: "components/vocab-builder-walkthrough-vb-tour" */).then(c => wrapFunctional(c.default || c))
export const VocabBuilderWalkthroughVbWalkthroughPrompt = () => import('../../components/VocabBuilder/Walkthrough/VbWalkthroughPrompt.vue' /* webpackChunkName: "components/vocab-builder-walkthrough-vb-walkthrough-prompt" */).then(c => wrapFunctional(c.default || c))
export const LiveClassesCommonLandingPageComponentsClassDurationDisplay = () => import('../../components/LiveClasses/Common/LandingPageComponents/ClassDurationDisplay.vue' /* webpackChunkName: "components/live-classes-common-landing-page-components-class-duration-display" */).then(c => wrapFunctional(c.default || c))
export const LiveClassesCommonLandingPageComponentsClassTopicsDemoCard = () => import('../../components/LiveClasses/Common/LandingPageComponents/ClassTopicsDemoCard.vue' /* webpackChunkName: "components/live-classes-common-landing-page-components-class-topics-demo-card" */).then(c => wrapFunctional(c.default || c))
export const LiveClassesCommonQuestionCard = () => import('../../components/LiveClasses/Common/LandingPageComponents/CommonQuestionCard.vue' /* webpackChunkName: "components/live-classes-common-question-card" */).then(c => wrapFunctional(c.default || c))
export const LiveClassesCommonLandingPageComponentsComparisonTable = () => import('../../components/LiveClasses/Common/LandingPageComponents/ComparisonTable.vue' /* webpackChunkName: "components/live-classes-common-landing-page-components-comparison-table" */).then(c => wrapFunctional(c.default || c))
export const LiveClassesCommonLandingPageComponentsComparisonTablePWA = () => import('../../components/LiveClasses/Common/LandingPageComponents/ComparisonTablePWA.vue' /* webpackChunkName: "components/live-classes-common-landing-page-components-comparison-table-p-w-a" */).then(c => wrapFunctional(c.default || c))
export const LiveClassesCommonLandingPageComponentsFaqSection = () => import('../../components/LiveClasses/Common/LandingPageComponents/FaqSection.vue' /* webpackChunkName: "components/live-classes-common-landing-page-components-faq-section" */).then(c => wrapFunctional(c.default || c))
export const LiveClassesCommonLandingPageComponentsHeroSectionPrimaryCard = () => import('../../components/LiveClasses/Common/LandingPageComponents/HeroSectionPrimaryCard.vue' /* webpackChunkName: "components/live-classes-common-landing-page-components-hero-section-primary-card" */).then(c => wrapFunctional(c.default || c))
export const LiveClassesCommonLandingPageComponentsRegisterBanner = () => import('../../components/LiveClasses/Common/LandingPageComponents/RegisterBanner.vue' /* webpackChunkName: "components/live-classes-common-landing-page-components-register-banner" */).then(c => wrapFunctional(c.default || c))
export const LiveClassesCommonLandingPageComponentsSecondaryFeatureCards = () => import('../../components/LiveClasses/Common/LandingPageComponents/SecondaryFeatureCards.vue' /* webpackChunkName: "components/live-classes-common-landing-page-components-secondary-feature-cards" */).then(c => wrapFunctional(c.default || c))
export const LiveClassesCommonLandingPageComponentsSectionHeading = () => import('../../components/LiveClasses/Common/LandingPageComponents/SectionHeading.vue' /* webpackChunkName: "components/live-classes-common-landing-page-components-section-heading" */).then(c => wrapFunctional(c.default || c))
export const LiveClassesCommonLandingPageComponentsTestimonialCard = () => import('../../components/LiveClasses/Common/LandingPageComponents/TestimonialCard.vue' /* webpackChunkName: "components/live-classes-common-landing-page-components-testimonial-card" */).then(c => wrapFunctional(c.default || c))
export const PremiumPlansPageCommonComponentsCompletePlanOverview = () => import('../../components/Premium/PlansPage/CommonComponents/CompletePlanOverview.vue' /* webpackChunkName: "components/premium-plans-page-common-components-complete-plan-overview" */).then(c => wrapFunctional(c.default || c))
export const PremiumPlansPageCommonComponentsEventsNudge = () => import('../../components/Premium/PlansPage/CommonComponents/EventsNudge.vue' /* webpackChunkName: "components/premium-plans-page-common-components-events-nudge" */).then(c => wrapFunctional(c.default || c))
export const PremiumPlansPageCommonComponentsGradientCheckBg = () => import('../../components/Premium/PlansPage/CommonComponents/GradientCheckBg.vue' /* webpackChunkName: "components/premium-plans-page-common-components-gradient-check-bg" */).then(c => wrapFunctional(c.default || c))
export const PremiumPlansPageCommonComponentsNormalPlanFeatures = () => import('../../components/Premium/PlansPage/CommonComponents/NormalPlanFeatures.vue' /* webpackChunkName: "components/premium-plans-page-common-components-normal-plan-features" */).then(c => wrapFunctional(c.default || c))
export const PremiumPlansPageCommonComponentsPlanCard = () => import('../../components/Premium/PlansPage/CommonComponents/PlanCard.vue' /* webpackChunkName: "components/premium-plans-page-common-components-plan-card" */).then(c => wrapFunctional(c.default || c))
export const PremiumPlansPageCommonComponentsPlanSliderSwitch = () => import('../../components/Premium/PlansPage/CommonComponents/PlanSliderSwitch.vue' /* webpackChunkName: "components/premium-plans-page-common-components-plan-slider-switch" */).then(c => wrapFunctional(c.default || c))
export const PremiumPlansPageCommonComponentsPopularPlanTag = () => import('../../components/Premium/PlansPage/CommonComponents/PopularPlanTag.vue' /* webpackChunkName: "components/premium-plans-page-common-components-popular-plan-tag" */).then(c => wrapFunctional(c.default || c))
export const PremiumPlansPageCommonComponentsPracticePlanOverview = () => import('../../components/Premium/PlansPage/CommonComponents/PracticePlanOverview.vue' /* webpackChunkName: "components/premium-plans-page-common-components-practice-plan-overview" */).then(c => wrapFunctional(c.default || c))
export const PremiumPlansPageCommonComponentsPrepFeatures = () => import('../../components/Premium/PlansPage/CommonComponents/PrepFeatures.vue' /* webpackChunkName: "components/premium-plans-page-common-components-prep-features" */).then(c => wrapFunctional(c.default || c))
export const PremiumPlansPageCommonComponentsPromotedPlanFeatures = () => import('../../components/Premium/PlansPage/CommonComponents/PromotedPlanFeatures.vue' /* webpackChunkName: "components/premium-plans-page-common-components-promoted-plan-features" */).then(c => wrapFunctional(c.default || c))
export const PremiumPlansPageCommonComponentsTestimonialsCarousel = () => import('../../components/Premium/PlansPage/CommonComponents/TestimonialsCarousel.vue' /* webpackChunkName: "components/premium-plans-page-common-components-testimonials-carousel" */).then(c => wrapFunctional(c.default || c))
export const PremiumPlansPageCommonComponentsUpsellingModal = () => import('../../components/Premium/PlansPage/CommonComponents/UpsellingModal.vue' /* webpackChunkName: "components/premium-plans-page-common-components-upselling-modal" */).then(c => wrapFunctional(c.default || c))
export const PremiumPlansPagePwaPlanCategorySwitch = () => import('../../components/Premium/PlansPage/Pwa/PlanCategorySwitch.vue' /* webpackChunkName: "components/premium-plans-page-pwa-plan-category-switch" */).then(c => wrapFunctional(c.default || c))
export const PremiumPlansPagePwa = () => import('../../components/Premium/PlansPage/Pwa/PlansPagePwa.vue' /* webpackChunkName: "components/premium-plans-page-pwa" */).then(c => wrapFunctional(c.default || c))
export const PremiumPlansPageWebComparisonTablePlanCard = () => import('../../components/Premium/PlansPage/Web/ComparisonTablePlanCard.vue' /* webpackChunkName: "components/premium-plans-page-web-comparison-table-plan-card" */).then(c => wrapFunctional(c.default || c))
export const PremiumPlansComparisonTable = () => import('../../components/Premium/PlansPage/Web/PlansComparisonTable.vue' /* webpackChunkName: "components/premium-plans-comparison-table" */).then(c => wrapFunctional(c.default || c))
export const PremiumPlansPageWeb = () => import('../../components/Premium/PlansPage/Web/PlansPageWeb.vue' /* webpackChunkName: "components/premium-plans-page-web" */).then(c => wrapFunctional(c.default || c))
export const PremiumPlansPageWebPriceCard = () => import('../../components/Premium/PlansPage/Web/PriceCard.vue' /* webpackChunkName: "components/premium-plans-page-web-price-card" */).then(c => wrapFunctional(c.default || c))
export const PriorityPlannerPlanLayoutNewResourceSectionAssignmentCard = () => import('../../components/PriorityPlanner/PlanLayoutNew/ResourceSection/AssignmentCard.vue' /* webpackChunkName: "components/priority-planner-plan-layout-new-resource-section-assignment-card" */).then(c => wrapFunctional(c.default || c))
export const PriorityPlannerPlanLayoutNewResourceCard = () => import('../../components/PriorityPlanner/PlanLayoutNew/ResourceSection/ResourceCard.vue' /* webpackChunkName: "components/priority-planner-plan-layout-new-resource-card" */).then(c => wrapFunctional(c.default || c))
export const PriorityPlannerPlanLayoutNewResourceSection = () => import('../../components/PriorityPlanner/PlanLayoutNew/ResourceSection/ResourceSection.vue' /* webpackChunkName: "components/priority-planner-plan-layout-new-resource-section" */).then(c => wrapFunctional(c.default || c))
export const PriorityPlannerPlanLayoutNewWeekCountDisplay = () => import('../../components/PriorityPlanner/PlanLayoutNew/WeekCounter/WeekCountDisplay.vue' /* webpackChunkName: "components/priority-planner-plan-layout-new-week-count-display" */).then(c => wrapFunctional(c.default || c))
export const VocabBuilderDashboardLeftPartVbDashboardNavbar = () => import('../../components/VocabBuilder/Dashboard/LeftPart/VbDashboardNavbar.vue' /* webpackChunkName: "components/vocab-builder-dashboard-left-part-vb-dashboard-navbar" */).then(c => wrapFunctional(c.default || c))
export const VocabBuilderPracticeFooter = () => import('../../components/VocabBuilder/Practice/Footer/PracticeFooter.vue' /* webpackChunkName: "components/vocab-builder-practice-footer" */).then(c => wrapFunctional(c.default || c))
export const VocabBuilderPracticeMainViewRoundCompletionView = () => import('../../components/VocabBuilder/Practice/MainView/RoundCompletionView.vue' /* webpackChunkName: "components/vocab-builder-practice-main-view-round-completion-view" */).then(c => wrapFunctional(c.default || c))
export const VocabBuilderPracticeModalsExitPracticeModal = () => import('../../components/VocabBuilder/Practice/Modals/ExitPracticeModal.vue' /* webpackChunkName: "components/vocab-builder-practice-modals-exit-practice-modal" */).then(c => wrapFunctional(c.default || c))
export const VocabBuilderPracticeNavbarSingleRoundQuestionMap = () => import('../../components/VocabBuilder/Practice/Navbar/SingleRoundQuestionMap.vue' /* webpackChunkName: "components/vocab-builder-practice-navbar-single-round-question-map" */).then(c => wrapFunctional(c.default || c))
export const VocabBuilderPracticeNavbarVbPracticeNavbar = () => import('../../components/VocabBuilder/Practice/Navbar/VbPracticeNavbar.vue' /* webpackChunkName: "components/vocab-builder-practice-navbar-vb-practice-navbar" */).then(c => wrapFunctional(c.default || c))
export const VocabBuilderDashboardLeftPartDailyStatusHeatmap = () => import('../../components/VocabBuilder/Dashboard/LeftPart/DailyStatus/Heatmap.vue' /* webpackChunkName: "components/vocab-builder-dashboard-left-part-daily-status-heatmap" */).then(c => wrapFunctional(c.default || c))
export const VocabBuilderDashboardLeftPartDailyStatusHeatmapInfoTooltip = () => import('../../components/VocabBuilder/Dashboard/LeftPart/DailyStatus/HeatmapInfoTooltip.vue' /* webpackChunkName: "components/vocab-builder-dashboard-left-part-daily-status-heatmap-info-tooltip" */).then(c => wrapFunctional(c.default || c))
export const VocabBuilderDashboardLeftPartDailyStatusHeatmapTile = () => import('../../components/VocabBuilder/Dashboard/LeftPart/DailyStatus/HeatmapTile.vue' /* webpackChunkName: "components/vocab-builder-dashboard-left-part-daily-status-heatmap-tile" */).then(c => wrapFunctional(c.default || c))
export const VocabBuilderDashboardRightPartPracticeCard = () => import('../../components/VocabBuilder/Dashboard/RightPart/Practice/PracticeCard.vue' /* webpackChunkName: "components/vocab-builder-dashboard-right-part-practice-card" */).then(c => wrapFunctional(c.default || c))
export const VocabBuilderDashboardRightPartPracticeCardContainer = () => import('../../components/VocabBuilder/Dashboard/RightPart/Practice/PracticeCardContainer.vue' /* webpackChunkName: "components/vocab-builder-dashboard-right-part-practice-card-container" */).then(c => wrapFunctional(c.default || c))
export const VocabBuilderDashboardRightPartRevisionDictionary = () => import('../../components/VocabBuilder/Dashboard/RightPart/Revision/Dictionary.vue' /* webpackChunkName: "components/vocab-builder-dashboard-right-part-revision-dictionary" */).then(c => wrapFunctional(c.default || c))
export const VocabBuilderPracticeMainViewQuestionsViewMultiSelectOptions = () => import('../../components/VocabBuilder/Practice/MainView/QuestionsView/MultiSelectOptions.vue' /* webpackChunkName: "components/vocab-builder-practice-main-view-questions-view-multi-select-options" */).then(c => wrapFunctional(c.default || c))
export const VocabBuilderPracticeMainViewQuestionsContainer = () => import('../../components/VocabBuilder/Practice/MainView/QuestionsView/QuestionsContainer.vue' /* webpackChunkName: "components/vocab-builder-practice-main-view-questions-container" */).then(c => wrapFunctional(c.default || c))
export const VocabBuilderPracticeMainViewQuestionsViewSingleSelectOptions = () => import('../../components/VocabBuilder/Practice/MainView/QuestionsView/SingleSelectOptions.vue' /* webpackChunkName: "components/vocab-builder-practice-main-view-questions-view-single-select-options" */).then(c => wrapFunctional(c.default || c))
export const VocabBuilderPracticeMainViewReadingContainer = () => import('../../components/VocabBuilder/Practice/MainView/ReadingView/ReadingContainer.vue' /* webpackChunkName: "components/vocab-builder-practice-main-view-reading-container" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
